<template>
    <div id="cart" v-if="cart">
        <div class="">
            <div v-if="mode == 'e-prescription'" class="bg-c-primary" id="printing">
                <div class="container">
                    <div class="p-3 position-relative d-block">
                        <button
                            type="button"
                            class="
                            btn
                            text-white
                            position-absolute
                            btn-sm
                            floating-btn
                            d-flex
                            text-arrow
                            "
                            @click="handleBack"
                        >
                            <!-- <img class="icon-svg mr-1" src="../../../assets/arrow-left.svg" /> -->
                            <i class="ri-arrow-left-line mr-2 align-middle"></i>Back
                        </button>
                        <div class="text-center">
                            <h4 class="text-white mb-0 ff-title fw-strong">
                            E-prescription Cart
                            </h4>
                        </div>
                    </div>
                </div>
            </div>

            <div class="container mt-5">
                <div class="title">
                    <h3 v-if="mode == 'default'">Shopping Cart</h3>
                    <h3 v-else-if="mode == 'e-prescription'" >Medication Item(s)</h3>
                </div>
                <div v-if="cartItems.length > 0" class="body">
                    <div class="table-items">
                        <div class="table-responsive d-lg-block d-md-block d-sm-none d-none">
                            <table class="table table-hover table-condensed table-bordered">
                                <thead>
                                    <tr>
                                        <th style="width:20%" class="text-center">Thumbnail</th>
                                        <th style="width:30%" class="text-center">Product</th>
                                        <th style="width:15%" class="text-center">Quantity</th>
                                        <th style="width:10%" class="text-center">Price</th>
                                        <th style="width:10%" class="text-center">Total</th>
                                        <th style="width:5%" class="text-center"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="item in cartItems" :key="item.id">
                                        <td data-th="Thumbnail" class="text-center">
                                            <img :src="getImageLink(item.product)" alt="..." class="img-responsive"/>
                                        </td>
                                        <td data-th="Product">
                                            <span class="nomargin">
                                                <router-link :to="`/store/products/${item.product.id}`">{{ item.product.title }}</router-link>
                                            </span>
                                        </td>
                                        <td data-th="Quantity" class="text-center">
                                            <div class="form-inline">
                                                <div class="form-group mb-2">
                                                    <input :disabled="mode == 'e-prescription'" type="number" class="form-control" value=1 min=1 pattern="[0-9]*" v-model.number="item.quantity" @blur="resetCartObj">
                                                </div>
                                            </div>
                                        </td>
                                        <td data-th="Price" class="text-center amount">RM {{ isVIP ? '0.00' : (getProdPrice(item.product)).toFixed(2) }}</td>
                                        <td data-th="Total" class="text-center amount">RM {{ isVIP ? '0.00' : (getProdPrice(item.product) * item.quantity).toFixed(2) }}</td>
                                        <td class="actions text-center" data-th="">
                                            <button v-if="mode == 'default'" class="btn btn-delete" @click="removeItemObj(item)"> <i class="far fa-trash-alt"></i> </button>	
                                            <button v-else-if="mode == 'e-prescription'" class="btn btn-delete" disabled> <i class="far fa-trash-alt"></i> </button>							
                                        </td>
                                    </tr>
                                </tbody>
                                <!-- <tfoot>
                                    <tr class="visible-xs">
                                        <td class="text-right" colspan="4"><strong>Total:</strong></td>
                                        <td colspan="2"><strong>RM 999.999</strong></td>
                                    </tr>
                                    <tr>
                                        <td><a href="#" class="btn btn-warning"><i class="fa fa-angle-left"></i> Continue Shopping</a></td>
                                        <td colspan="2" class="hidden-xs"></td>
                                        <td class="hidden-xs text-center"><strong>Total $1.99</strong></td>
                                        <td><a href="#" class="btn btn-success btn-block">Checkout <i class="fa fa-angle-right"></i></a></td>
                                    </tr>
                                </tfoot> -->
                            </table>
                        </div>
                        <!-- Specific for mobile table -->
                        <div id="cart-mobile-table" class="table-responsive d-md-none d-sm-block d-block">
                            <table class="table table-hover table-condensed table-bordered">
                                <thead>
                                    <tr>
                                        <th style="width:16%" class="text-center">Thumbnail</th>
                                        <th style="width:84%" colspan="2" class="text-center">Product</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="item in cartItems" :key="item.id">
                                        <td data-th="Thumbnail" class="text-center">
                                            <img :src="getImageLink(item.product)" alt="..." class="img-responsive"/>
                                        </td>
                                        <td data-th="Product">
                                            <span class="nomargin">
                                                <router-link :to="`/store/products/${item.product.id}`">{{ item.product.title }}</router-link>
                                            </span>
                                            <div class="form-inline">
                                                <div class="form-group mb-2 mt-2 p-0" style="width:45px;">
                                                    <input type="number" class="form-control" value=1 min=1 pattern="[0-9]*" v-model.number="item.quantity" @blur="resetCartObj">
                                                </div>
                                            </div>
                                            <span class="amount nomargin">
                                                RM {{ isVIP ? '0.00' : (getProdPrice(item.product)).toFixed(2) }}
                                            </span>
                                        </td>
                                        <!-- <td data-th="Price" class="text-center amount">RM {{ isVIP ? '0.00' : (item.product.variants[0].price).toFixed(2) }}</td>
                                        <td data-th="Total" class="text-center amount">RM {{ isVIP ? '0.00' : (item.product.variants[0].price * item.quantity).toFixed(2) }}</td> -->
                                        <td class="actions text-center" data-th="">
                                            <button class="btn btn-delete" @click="removeItemObj(item)"> <i class="far fa-trash-alt"></i> </button>								
                                        </td>
                                    </tr>
                                </tbody>
                                <!-- <tfoot>
                                    <tr class="visible-xs">
                                        <td class="text-right" colspan="4"><strong>Total:</strong></td>
                                        <td colspan="2"><strong>RM 999.999</strong></td>
                                    </tr>
                                    <tr>
                                        <td><a href="#" class="btn btn-warning"><i class="fa fa-angle-left"></i> Continue Shopping</a></td>
                                        <td colspan="2" class="hidden-xs"></td>
                                        <td class="hidden-xs text-center"><strong>Total $1.99</strong></td>
                                        <td><a href="#" class="btn btn-success btn-block">Checkout <i class="fa fa-angle-right"></i></a></td>
                                    </tr>
                                </tfoot> -->
                            </table>
                        </div>
                    </div>
                </div>
                <div v-else class="body empty-cart">
                    <p>Your cart is currently empty.</p>
                    <p><router-link to="/store">Continue browsing here.</router-link></p>
                </div>
                <div v-if="cartItems.length > 0" class="foot">
                    <div class="row">
                        <div class="col-md-12">
                            <span>Subtotal </span>
                            <span class="subtotal">{{ isVIP? '0.00' : subtotal }}</span>
                        </div>
                        <div class="col-12 mt-3">
                            <router-link v-if="mode !== 'e-prescription' " to="/">Continue shopping.</router-link>
                            <button v-if="mode == 'default'" class="btn" @click="$router.push('/store/checkout')">Check out</button>
                            <button v-if="mode == 'e-prescription'" class="btn" @click="$router.push(`/store/checkout?mode=e-prescription&e-prescription=${ePrescription.id}`)">Check out</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import API from '../../../utils/API'
import { mapGetters, mapMutations } from 'vuex'
import { checkProductPromo } from './../../../utils/ProductUtils'

export default {
    data(){
        return {
            // cart: null
            isVIP: false,
            subtotal: 0.00,
            cartItems: [],
            isDoneSpecialCalc: false,
            mode: 'default', //default or e-prescription,
            ePrescription: null
        }
    },
    computed: {
        ...mapGetters(['cart', 'currentCustomer']),
    },
    methods: {
        ...mapMutations(['resetCart', 'removeItem']),
        initPromo(){
            this.cartItems.forEach(item => {
                let prodPromoPrice = checkProductPromo(item.product)
                if(prodPromoPrice) item.product.promo_price = prodPromoPrice //craete new subproperty of promo_price if exist
            })

            this.cartItems.forEach(item => {
                let prodPromo = checkProductPromo(item.product)
                if(prodPromo) { //craete new subproperties if promo exist
                    item.product.promo_price = prodPromo.promo_price
                    // item.product.promo_badge_url = prodPromo.promo_badge
                }
            })
        },
        getProdPrice(_product){
            if(!this.isDoneSpecialCalc && _product.is_jovianselcare_prod == true){
                return 0.00
            }

            if(_product.promo_price) return _product.promo_price
            else return _product.variants[0].price
        },
        resetCartObj(){
            this.resetCart(this.cart)

            //reset subTotal
            this.setSubtotal()
        },
        removeItemObj(_item){
            this.removeItem(_item.product)

            //reset subTotal
            this.setSubtotal()
        },
        setSubtotal(){
            let sub = 0.00
            this.cartItems.forEach(item => sub += (this.getProdPrice(item.product) * item.quantity))
            this.subtotal = sub.toFixed(2)
        },
        getImageLink(_product) {
            if(_product.variants.length == 0 || !_product.variants[0].image || _product.variants[0].image.length == 0) return '/img/default_no_image.jpg'
            else return API.getAPIUrl() + _product.variants[0].image[0].url //get the first image
        },
        handleBack() {
            this.$router.push({
                path: "/e-prescription/check/"+this.ePrescription.id,
            });
        },
        initEPrescriptionCart(){
            //for JovianSelcare item only
            //start call BE to check pricing and ready to checkout !!
            return new Promise(async(resolve, reject) => {
                for(let item of this.cartItems){
                    let res01 = await API.post('/custom/jovianselcare-retrieve-product', { productID: item.product.jovianselcare_prod_id })
                    if(item.product.variants.length == 0){
                        let variant = { price: parseFloat(res01.data.product.priceRangeV2.maxVariantPrice.amount), jovianselcare_variant_id: res01.data.product.variants.edges[0].node.id }
                        item.product.variants.push(variant) //default at DB, no variant yet for jovianselcare product
                    } else {
                        item.product.variants[0].price = parseFloat(res01.data.product.priceRangeV2.maxVariantPrice.amount)
                        item.product.variants[0].jovianselcare_variant_id = res01.data.product.variants.edges[0].node.id
                    }
                }

                this.isDoneSpecialCalc = true
                this.setSubtotal() //set once again

                //consider reset for new cart
                let newCart = this.cart
                newCart.items = this.cartItems
                this.resetCart(newCart)
                resolve(0)
            })
        }
    },
    async created(){
        //if for prescription
        if(this.$route.query.mode && this.$route.query.mode == 'e-prescription'){
            this.cartItems = this.cart.items.filter(i => i.product.is_jovianselcare_prod == true)
            this.mode = 'e-prescription'

            const res = await API.get('/prescriptions/'+ this.$route.query['e-prescription'])
            this.ePrescription = res.data

            await this.initEPrescriptionCart()
        } 
        //if default
        else {
            this.cartItems = this.cart.items.filter(i => !i.product.is_jovianselcare_prod || i.product.is_jovianselcare_prod == false)
            this.mode = 'default'
        }
    },
    async mounted(){
        //set subtotal
        this.setSubtotal()

        //Set all price to 0.00 if customer type == vip
        if(this.currentCustomer){
            if(this.currentCustomer.user.customer.cust_type == 'vip') this.isVIP = true
            else this.isVIP = false
        }

        this.initPromo()
    }
}
</script>

<style scoped>
.title h3{
    line-height: 1.4;
    /*font-family: "Roboto Slab",serif;*/
    text-transform: uppercase;
    font-weight: bold;
    color: #000;
    letter-spacing: 0.2em;
    font-size: 1.57143em;
}

.body {
    margin-top: 25px;
}
.body.empty-cart a {
    color: initial;
    text-decoration: unset;
}

.table-items table thead th {
    font-size: 14px;
    /*font-family: "Roboto Slab",serif;*/
    letter-spacing: 0.2em;
    text-transform: uppercase;
    line-height: normal;
    color: #000;
}
.table-items table tbody td img {
    width: 50%;
}
.table-items table tbody td {
    font-size: 14px;
    vertical-align:middle
}
.table-items table tbody td a {
    letter-spacing: 0.075em;
    /*font-family: "Roboto Slab",serif;*/
    font-weight: bold;
    color: #000;
}
.table-items table tbody td.amount, .amount {
    color: #a91a18;
}
.table-items table tbody td .form-group {
    padding: 0px 30px;
    width: 100%;
}
.table-items table tbody td .form-group input[type=number] {
    width: 100%;
    height: 45px;
    border-radius: unset;
    border: 2px solid #e5e5e5;
    color: #999;
}
.table-items table tbody td .btn.btn-delete {
    color: #ea7b7b;
}

#cart-mobile-table table thead th {
    font-size: 12px;
}
#cart-mobile-table table tbody td img {
    width: 90%;
}
#cart-mobile-table table tbody td {
    font-size: 12px;
    vertical-align: middle;
}

.foot {
    margin-top: 30px;
    text-align: right;
}
.foot span.subtotal {
    margin-left: 5px;
    font-size: 1.42857em;
    font-weight: bold;
}
.foot button {
    border-radius: 0;
    border: 2px solid transparent;
    line-height: 1.42857;
    padding: 8px 26px;
    font-weight: bold;
    font-size: 14px;
    letter-spacing: 0.15em;
    /* font-family: "Karla",sans-serif; */
    background-color: #747474;
    color: #fff;
    text-transform: uppercase;
}
.foot button:hover {
    background-color: #000;
    color: #fff;
}
.foot a {
    margin-right: 15px;
    color: #333;
    font-style: italic;
    /* vertical-align: bottom; */
}


/** Table mobile view */
/* @media screen and (max-width: 500px) {
    .table-items table tbody td .form-control{
		width:20%;
		display: inline !important;
	}
	.actions .btn{
		width:36%;
		margin:1.5em 0;
	}
	
	.actions .btn-info{
		float:left;
	}
	.actions .btn-danger{
		float:right;
	}
	
	.table-items table thead { display: none; }
    .table-items table tbody td { display: block; padding: .6rem; min-width:320px;}
    .table-items table tbody tr { display: block; margin-bottom: 20px;}
    .table-items table tbody td:nth-child(2) {
        text-align: center;
    }
    .table-items table tbody td:first-child:before,
    .table-items table tbody td:nth-child(2):before {
        display: none;
	}
	.table-items table tbody td:before {
		content: attr(data-th); font-weight: bold;
		display: inline-block; width: 8rem;
    }
    .table-items table tbody td .form-inline {
        display: inline-block;
        width: 20%;
    }
    .table-items table tbody td .form-group {
        padding: 0px;
    }
	
	
	
	.table-items table tfoot td{display:block; }
    .table-items table tfoot td .btn{display:block;}
    
    .foot {text-align: center;}
	
} */


/** General */
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    opacity: 1;
}
</style>